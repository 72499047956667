<template>
  <div>
 <img v-if="!show" style="justify-content: center; align-items: center;width:100%;" @click="openbox(show)" src="../assets/box.gif" />
    <div v-if="show" style="margin: 40px; text-align: center">
      <div class="mb-5" style="padding:20px">
        <img
          style="justify-content: center; align-items: center"
          src="../assets/birthday-cake.png"
          width="150"
        />
      </div>
          <h1 style="font-size: 1.5rem">สุขสันต์วันเกิดนะแก</h1>
           <br/>
          <p>
            วันเกิดปีนี้แกมีเค้า ปีหน้าและในปีต่อๆไปก็ยังจะมีเค้าแบบนี้ตลอดนะ
          </p>
          <p>
            น่าจะรู้อยู่แล้วว่าเป็นคนสำคัญสำหรับเค้า
            จริงๆก็เป็นมาตั้งแต่มหาลัยละ แค่ไม่ได้มีโอกาสบอก
          </p>
          <p>ถ้ามีเรื่องอะไรที่ไม่โอเคก็บอกเค้าได้นะทุกเรื่องเลย</p>
          <p>ไม่ว่าจะเกิดอะไรขึ้นขอให้รู้ไว้ว่าแกยังมีเค้าอยู่ข้างๆนะ</p>
          
          <p>ขอให้ปีนี้เป็นปีที่ดีนะ เจอแต่เรื่องดีๆ</p>
          
          <p style="color: white">
            On your birthday, I think of giving you the special gift in the
            world. But then I realized that is not possible because you yourself
            are the special gift in the world.
          </p>
        <button class="btn btn-primary" @click="openbox(show)">Close</button>
    </div>
  <!-- <HelloWorld /> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      show: false,
    };
  },
  components: {
    // HelloWorld
  },
  methods: {
    openbox(text) {
      // console.log(text);
      if (text == false) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style>
body {
  float: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #9E83EA;
}
p {
  margin-top: 20px;
}
</style>

