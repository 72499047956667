import Vue from "vue";
import Router from "vue-router";
import HBD2024 from "./components/HBD2024.vue"
import HBD2023 from "./components/HBD2023.vue"
import HBD2022 from "./components/HBD2022.vue"
import Christmas from "./components/Christmas.vue"

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/HBD2024",
      name: "HBD2024",
      component: HBD2024
    },
    {
      path: "/HBD2023",
      alias: "/HBD2023",
      name: "HBD2023",
      component: HBD2023
    },
    {
      path: "/HBD2022",
      alias: "/HBD2022",
      name: "HBD2022",
      component: HBD2022
    },
    {
      path: "/xmas",
      alias: "/xmas",
      name: "xmas",
      component: Christmas
    },
  ]
});


// router.beforeEach((to, from, next) => {
//   const publicPages = ['/','/HBD2022','/HBD2023','/HBD2024'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('hbd');
//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/');
//     // next();
//   } else {
//     next('/');
//   }

// });

export default router;
