<template>
  <div>
    <div class="row" style="border:2px solid;">
      <div class="col-md-12">
        <img src="../assets/tinytan.gif" style="width: 100%" />
      </div>
    </div>
    <div style="color: white" class="mt-5 mb-5">
      <h1 style="font-size: 1.5rem">สุขสันต์วันเกิดนะแก</h1>
      <p>
        โตขึ้นอีกปีละนะ รู้อยู่แล้วแหละว่าแกก็มีความสุขในทุก ๆ วันอยู่แล้ว<br />
        งั้นก็ขอให้มีความสุขมาก ๆ ขึ้นไปอีกในทุก ๆ วันนะ
      </p>
      <p>
        ขอให้มีแต่เรื่องราวดี ๆ และสิ่งดี ๆ เข้ามา คิดหวังสิ่งไหนก็ขอให้สมปรารถนา สุขภาพร่างกายแข็งแรง
      </p>
      <p>
        เค้าก็จะบอกเหมือนเดิมนะว่า ถ้ามีเรื่องอะไรที่ไม่สบายใจ หรือรู้สึกไม่ดีก็บอกได้ตลอดเลย<br />
        จริง ๆ ก็แชร์มาได้ทุกเรื่องอะแหละ เอาเป็นว่าไม่ว่าจะเกิดอะไรขึ้นแกก็ยังมีเค้าอยู่นะ
      </p>
      <p>
      อันนี้สำคัญมาก เค้าขอโทษที่เค้ายุ่งกับแกมากเกินไป กำลังปรับตัวอยู่ และคิดว่าตอนนี้น่าจะปรับตัวได้แล้ว
      </p>
      <p>ปีที่ผ่านมาก็ขอบคุณสำหรับหลาย ๆ เรื่อง โดยเฉพาะเรื่องที่แกพยายามปรับตัวกับเรื่องที่เคยขอไป</p>  
      <!-- <p>ปีนี้อยากได้อะไรก็ไปซื้อเอานะ น่าจะดีกว่าให้เค้าเลือกให้</p><br />         -->
    </div>
    <div class="row" style="border:2px solid;">
      <div class="col-sm-1" style="background-color: #9966cc"></div>
      <div class="col-sm-3" style="background-color: #9966cc">
        <img src="../assets/bt21-koya.gif" style="width: 100%" />
      </div>
      <div class="col-sm-4" style="background-color: #9966cc">
        <img src="../assets/bt21-celebrate.gif" style="width: 100%" />
      </div>
      <div class="col-sm-3" style="background-color: #9966cc">
        <img src="../assets/bt21-rj.gif" style="width: 100%" />
      </div>
      <div class="col-sm-1" style="background-color: #9966cc"></div>
    </div>
     <div class="row" style="border:2px solid;">
      <!-- <div class="col-sm-12"><img src="../assets/chimmy.gif" style="width: 70%" /></div> -->
     
    </div>
    <!-- <audio autoplay controls>
  <source src="../assets/birthday.mp3" type="audio/mp3">
</audio> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      showbt:true
    }
  },
  methods: {
    doSetTimeout(i) {
  setTimeout(function() {
    // alert(i);
    if(i % 10 == 0) {
    // alert("The number is even.");
    this.showbt = true
    console.log('even',this.showbt);
}

// if the number is odd
else {
    // alert("The number is odd.");
    this.showbt = false
    console.log('odd',this.showbt);
}
  }, 100);
}

  },
  mounted(){
//     if( window.localStorage )
//   {
//     if( !localStorage.getItem('hbdfirstLoad') )
//     {
//       localStorage['hbdfirstLoad'] = true;
      
//       setTimeout(() => {
//   alert('นี่ อย่ามัวแต่ดูการ์ตูนล่ะ อ่านคำอวยพรด้วย 55555')
// }, 1000)
//     }  
//     else
//       localStorage.removeItem('hbdfirstLoad');
//   }
  //  var i=0;
  // while (i<100) {
  //   this.doSetTimeout(i);
  //   i++;
  // }
  }
};
</script>

<style>
body {
  float: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #9966cc;
}
p {
  margin-top: 20px;
}
.col-sm-3 {
  padding: 0px;
}
.col-sm-4 {
  padding: 0px;
}
</style>

