<template>
  <div>
    <router-view />
  </div>
</template>


<script>

export default {
  name: "app",
  components: {
  },
  data() {
    return {
      show: true,
      menu: []
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  //   if (this.$route.path == '/') {
  //     this.$router.push('/HBD2024') 
  //   }
  //  console.log(this.$route.path);
  },
};
</script>

<style>
/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #808000;
  color: white;
}
</style>